.editLiveClass .el-form-liveClass {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.editLiveClass .el-form-liveClass .form-item {
  width: 45%;
}
.editLiveClass .el-form-liveClass .form-period .el-form-item__content {
  display: flex;
  align-items: center;
}
.editLiveClass .el-form-liveClass .form-period .el-form-item__content .el-select {
  flex: 1;
}
.editLiveClass .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
