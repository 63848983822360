.addClassSubject .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.addClassSubject .el-form .form-item {
  width: 100%;
  padding-left: 10rem;
}
.addClassSubject .el-form .padd0 {
  padding-left: 0;
}
.addClassSubject .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addClassSubject .el-textarea .el-textarea__inner {
  resize: none;
}
.addClassSubject .paraCompleteConditionInput {
  display: inline-block;
}
.addClassSubject .paraCompleteConditionInput .el-input {
  display: inline-block;
  width: 40% !important;
  margin: 0 10px;
  height: 32px;
}
