


















































































































.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
